/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

import * as Flow from '@flowjs/flow.js';

declare var angular: angular.IAngularStatic;

import { AuthConstant } from '../../../constants/auth.constant';

interface Customization {
    offerLength?: string;
    endCardOffer?: string;
    offerId?: string;
    offerType?: string;
    endCardTagline?: string;
    endCardBackground?: any; // A file

    isci?: string;
    title?: string;
    market?: string;
    dealerLocation?: string;
    dealerUrl?: string;
    legal?: string;
    voRead?: string;
    voFile?: any; // A file

    endCardBackgroundIncluded?: boolean;
    voFileIncluded?: boolean;

    endCardBackgroundFilename?: string;
    voFileIncludedFilename?: string;

    specialInstructions?: string;
}

export const RequestCustomizationDialog = {
    selector: 'requestCustomizationDialog',
    bindings: {},
    scope: {},
    replace: true,
    template: require('./requestCustomizationDialog-template.html'),
    controller: class RequestCustomizationDialogController {
        //Bindable
        public customizationInfo: Customization = {
            dealerLocation: "On Hand @ CAD",
            dealerUrl: "On Hand @ CAD",
        } as Customization;
        public submitting: boolean = false;
        public authConstants: any = new AuthConstant();
        public backgroundFileErrors:any = [];
        public voFileErrors:any = [];

        static get $inject() {
            return [
                '$mdDialog',
                '$window',
                'delivery',
                'DeliveryResourceFactory',
                'EndPointService',
                'NotificationService',
            ];
        }

        constructor(
            private $mdDialog: any,
            public $window: any,
            public delivery: any,
            public DeliveryResourceFactory: any,
            public EndPointService: any,
            public NotificationService: any,
        ) {
            /* BINDABLE: DATA */
        }

        close() {
            this.$mdDialog.hide(this.customizationInfo);
        }

        submit(repeat:boolean) {
            // Submit the request to Node
            let vm = this;

            vm.submitting = true;
            let uploader = new Flow({
                target: vm.EndPointService.tapUploadEndPoint,
                headers: () => {
                    let accountHash = JSON.parse(
                        vm.$window.localStorage[vm.authConstants.session.ACCOUNT_HASH]
                    );
                    let sessionData = JSON.parse(
                        vm.$window.sessionStorage[vm.authConstants.session.SESSION_DATA]
                    );

                    return {
                        Authorization: 'Bearer ' + accountHash[sessionData.accountId],
                    };
                },
                testMethod: false,
                // singleFile: true,
                fileParameterName: 'file',
                uploadMethod: 'POST',
                withCredentials: true,
                allowDuplicateUploads: true,
                // 5Mb chunks, should force everything to go at once, since it's limited to that max size
                chunkSize: 5 * 1024 * 1024,
                simultaneousUploads: 3,
                query: {
                    ignoreLoadingBar: true,
                    waitForAutoConversions: false,
                },
                testChunks: false,
            });

            if(vm.customizationInfo.endCardBackground) {
                uploader.addFile(vm.customizationInfo.endCardBackground);
            }

            if(vm.customizationInfo.voFile) {
                uploader.addFile(vm.customizationInfo.voFile);
            }


            let newAssets:any[] = [];

            uploader.upload();

            uploader.on('fileSuccess', function (file:any, message:number) {
                newAssets.push({
                    filename: file.name,
                    id: message,
                });
            });

            uploader.on('complete', function () {
                vm.customizationInfo.endCardBackgroundIncluded = !!vm.customizationInfo.endCardBackground;
                vm.customizationInfo.voFileIncluded = !!vm.customizationInfo.voFile;

                vm.customizationInfo.endCardBackgroundFilename = (vm.customizationInfo.endCardBackgroundIncluded) ? vm.customizationInfo.endCardBackground.name : '';
                vm.customizationInfo.voFileIncludedFilename = (vm.customizationInfo.voFileIncluded) ? vm.customizationInfo.voFile.name : '';

                // Make the call to submit to Prod Services
                // use newAssetIds to populate the request to send an email
                delete vm.customizationInfo.endCardBackground;
                delete vm.customizationInfo.voFile;

                let options = {
                    attachments: newAssets,
                    baseIsci: vm.delivery.Spot.isci,
                    baseTitle: vm.delivery.Spot.title,
                    ...vm.customizationInfo,
                };

                vm.DeliveryResourceFactory.sendForCustomization(
                    { id: vm.delivery.id },
                    options,
                    function success() {
                        vm.submitting = false;
                        // NotificationService toast should be displayed
                        vm.NotificationService.showNotificationToast(
                            'Delivery submitted for Customization'
                        );

                        if (repeat) {
                            delete vm.customizationInfo.isci;
                            delete vm.customizationInfo.title;
                            delete vm.customizationInfo.endCardBackground;
                            delete vm.customizationInfo.voFile;
                        } else {
                            vm.$mdDialog.hide();
                        }
                    },
                    function failure(err:any) {
                        vm.submitting = false;
                        // NotificationService error should be displayed
                        vm.NotificationService.showNotificationToast(
                            'Delivery failed to submit for Customization',
                            err.data
                        );
                        vm.$mdDialog.hide(false);
                    }
                );
            });
        }

        cancel() {
            this.$mdDialog.cancel();
        }

        $onInit() {}
    },
    controllerAs: 'vm',
};
